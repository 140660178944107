import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Heading() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto pt-20">
      <h1 className="text-[#3D41F1] text-[31px] font-bold text-center">
        {t('pricing.step1.title.0')}{' '}
        <span className="text-black">{t('pricing.step1.title.1')}</span>
      </h1>
      <p className="text-[#6A7683] text-[17px] mt-4 font-normal text-center">
        {t('pricing.step1.descriptions.0')}
        <br />
        {t('pricing.step1.descriptions.1')}
      </p>
    </div>
  );
}
