import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../contexts/UserContext'
import Card from '../../components/pricing/Card'
import Heading from '../../components/pricing/Heading'
import NavBar from '../../components/pricing/NavBar'
import Footer from '../../components/global/Footer'
import LoadingScreen from '../../components/router/LoadingScreen';
import CancelSub from '../../components/pricing/CancelSub'



export default function step1() {

    const [loading, setLoading] = useState(true);
    const [premium, setPremium] = useState(false);
    const [subid, setSubid] = useState('');
    const [userid, setUserid] = useState('');
    const { user } = useContext(UserContext);

    useEffect(async () => {
        // console.log(location.search);
        if (user) {
            const localUser = JSON.parse(localStorage.getItem('user'));
            setPremium(localUser.isPremiumUser);
            setSubid(localUser.subscriptionId);
            setUserid(localUser.uid);
            setLoading(false);
        } else {
            setLoading(false);
        }

    }, []);


    if (loading) {
        return <LoadingScreen />;
    }
    return (
        <>
            <NavBar />
            {premium === true ? <CancelSub subid={subid} userid={userid} /> : <div className='w-full bg-[#F2F5FA]'>
                <Heading />
                <Card />
            </div>}

            <Footer />
        </>
    )
}
