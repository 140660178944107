import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import UserContext from '../../contexts/UserContext';

import TickWhite from '../../../static/images/tick-white.svg';
import GreenWhite from '../../../static/images/green-tick.svg';

import Next from '../../../static/images/next-arrow.svg';
import Monthly from '../../../static/images/monthly.svg';
import Weekly from '../../../static/images/weekly.svg';
import Yearly from '../../../static/images/yearly.svg';
import Paypal from '../../../static/images/paypal.svg';
import Master from '../../../static/images/master.svg';
import Visa from '../../../static/images/visa.svg';
import Express from '../../../static/images/express.svg';
import _config from '../../utils/config';

const ProcessingButton = () => {
  const { t } = useTranslation();
  return (
    <>
      <svg
        className="animate-spin -inline-block w-8 h-8 border-4 rounded-full"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx={12}
          cy={12}
          r={10}
          stroke="currentColor"
          strokewidth={4}
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span>{t('shared.buttons.processing')}</span>
    </>
  );
};

export default function Card() {
  const { setPaymentDetails } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onPriceClick = async (id, amount, type) => {
    if (!user) {
      navigate(`/signup`);
    } else {
      // navigate(`/pricing/step2`);
      const paymentDetails = {
        priceId: id,
        priceAmount: amount,
        priceType: type,
      };
      setPaymentDetails(paymentDetails);

      if (paymentDetails.priceId) {
        setLoading(paymentDetails.priceType);
        let userId = '';
        const usersInfo = localStorage.getItem('user');
        if (usersInfo) {
          const { uid } = JSON.parse(usersInfo);
          userId = uid;
        }
        const config = {
          method: 'POST',
          url: '/api/create-checkout-session',
          headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': 'https://www.simplecv.me',
            Accept: 'application/json',
          },
          data: JSON.stringify({
            priceId: paymentDetails.priceId,
            userId,
          }),
        };

        axios(config)
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);

              const { url } = res.data.data;
              window.location.href = `${url}`;
            }
          })
          .catch((err) => {
            setLoading(false);

            console.log(err);
          });
      }
    }
  };
  return (
    <>
      <div className="container m-auto flex flex-wrap justify-center items-center mt-12 space-y-6 md:space-y-0">
        <div className="w-full md:w-[25%] flex justify-end h-[400px]">
          <div className="w-full  flex flex-col space-y-6 justify-center bg-white px-8 py-2 rounded-[45px] border-8 border-white">
            <div className="flex justify-center md:justify-start items-center space-x-3">
              <img src={Weekly} alt="weekly" />
              <div className="flex flex-col ">
                <h3 className="text-[#262B33] text-[19px] font-medium">
                  {t('pricing.step1.cards.1.title')}
                </h3>
                <p className="text-[#262B33] text-[26px] font-bold pl-5">
                  <span className="text-[#B7B8BC] absolute text-[16px] mt-[-1px] ml-[-11px] ">
                    $
                  </span>
                  {t('pricing.step1.cards.1.price')}
                </p>
              </div>
            </div>
            <div className="border-b-[1px] border-solid border-[#B4B6C2]" />
            <div className="flex w-full items-center justify-start space-x-6">
              <img src={GreenWhite} alt="tick white" />
              <p className="text-[#67707E] text-[14px]">
                {t('pricing.step1.cards.0.features.0.0')}{' '}
                <span className="text-[#262B33] font-bold">
                  {' '}
                  {t('pricing.step1.cards.1.features.0.1')}
                </span>{' '}
                {t('pricing.step1.cards.1.features.0.2')}
              </p>
            </div>
            {/* <div className='flex w-full items-center justify-start space-x-6'>
                            <img src={GreenWhite} alt="tick white" />
                            <p className='text-[#67707E] text-[14px]'>Unlimited <span className='text-[#262B33] font-bold'>Cover</span> Letters</p>
                        </div> */}
            <div className="flex w-full items-center justify-start space-x-6">
              <img src={GreenWhite} alt="tick white" />
              <p className="text-[#67707E] text-[14px]">
                {t('pricing.step1.cards.1.features.1.0')}{' '}
                <span className="text-[#262B33] font-bold">
                  {t('pricing.step1.cards.1.features.1.1')}{' '}
                </span>
              </p>
            </div>
            <div className="flex w-full items-center justify-start space-x-6">
              <img src={GreenWhite} alt="tick white" />
              <p className="text-[#67707E] text-[14px]">
                {t('pricing.step1.cards.1.features.2.0')}{' '}
                <span className="text-[#262B33] font-bold">
                  {t('pricing.step1.cards.1.features.2.1')}{' '}
                </span>{' '}
              </p>
            </div>

            {/* <div className='flex w-full items-center justify-start space-x-6'>
                            <img src={GreenWhite} alt="tick white" />
                            <p className='text-[#67707E] text-[14px]'>Free resume critique from an <span className='text-[#262B33] font-bold'>HR expert</span></p>
                        </div> */}
            <button
              className="bg-[#3D41F1] flex justify-center  items-center space-x-6 text-white text-[21px] font-medium rounded-lg py-6 px-8"
              onClick={() =>
                onPriceClick(_config.subscriptions.monthly, 9.99, 'Monthly')
              }
            >
              {loading === 'Monthly' ? (
                <ProcessingButton />
              ) : (
                <>
                  <h4>{t('shared.buttons.choosePlan')}</h4>{' '}
                  <img src={Next} alt="next" />
                </>
              )}
            </button>
          </div>
        </div>
        <div className="relative w-full md:w-[30%] flex h-[550px] justify-center">
          <div className="w-full  flex flex-col space-y-8 justify-center bg-[#1A1D29] px-8 py-2  rounded-[45px] border-8 border-white">
            <div className="flex justify-center md:justify-start items-center space-x-3">
              <h4 className="absolute text-center right-10 top-10 font-semibold text-[12px] bg-[#3D42F3] text-white rounded-full py-2 w-[93px] uppercase">
                {t('pricing.step1.cards.0.popular')}
              </h4>
              <img src={Monthly} alt="monthly" />
              <div className="flex flex-col ">
                <h3 className="text-[#FEFEFE] text-[22px] font-medium">
                  {t('pricing.step1.cards.0.title')}
                </h3>
                <p className="text-[#FEFEFE] text-[31px] font-bold pl-4">
                  {/* <span className="text-[#B7B8BC] absolute text-[18px] mt-[-1px] ml-[-12px] ">
                    $
                  </span> */}
                  {t('pricing.step1.cards.0.price')}
                </p>
              </div>
            </div>
            <div className="border-b-[1px] border-solid border-[#B4B6C2]" />
            <div className="flex w-full items-center justify-start space-x-6">
              <img src={TickWhite} alt="tick white" />
              <p className="text-[#B4B6C2] text-[16px]">
                {t('pricing.step1.cards.0.features.0.0')}{' '}
                <span className="text-white">
                  {t('pricing.step1.cards.0.features.0.1')}
                </span>{' '}
                {t('pricing.step1.cards.0.features.0.2')}
              </p>
            </div>
            {/* <div className='flex w-full items-center justify-start space-x-6'>
                            <img src={TickWhite} alt="tick white" />
                            <p className='text-[#B4B6C2] text-[16px]'>Unlimited <span className='text-white'>Cover</span> Letters</p>
                        </div> */}
            <div className="flex w-full items-center justify-start space-x-6">
              <img src={TickWhite} alt="tick white" />
              <p className="text-[#B4B6C2] text-[16px]">
                {t('pricing.step1.cards.0.features.1.0')}{' '}
                <span className="text-white">
                  {t('pricing.step1.cards.0.features.1.1')}
                </span>
              </p>
            </div>

            <div className="flex w-full items-center justify-start space-x-6">
              <img src={TickWhite} alt="tick white" />
              <p className="text-[#B4B6C2] text-[16px]">
                <span className="text-white">
                  {t('pricing.step1.cards.0.features.2.0')}
                </span>{' '}
                {t('pricing.step1.cards.0.features.2.1')}
              </p>
            </div>

            <div className="flex w-full items-center justify-start space-x-6">
              <img src={TickWhite} alt="tick white" />
              <p className="text-[#B4B6C2] text-[16px]">
                <span className="text-white">
                  {t('pricing.step1.cards.0.features.3.0')}
                </span>{' '}
                {t('pricing.step1.cards.0.features.3.1')}
              </p>
            </div>

            {/* <div className='flex w-full items-center justify-start space-x-6'>
                            <img src={TickWhite} alt="tick white" />
                            <p className='text-[#B4B6C2] text-[16px]'>Free resume critique from an <span className='text-white'>HR expert</span></p>
                        </div> */}

            <button
              className="bg-[#3D41F1] flex justify-center  items-center space-x-6 text-white text-[21px] font-medium rounded-lg py-6 px-8"
              onClick={() =>
                onPriceClick(_config.subscriptions.weekly, 2.99, 'Weekly')
              }
            >
              {loading === 'Weekly' ? (
                <ProcessingButton />
              ) : (
                <>
                  <h4>{t('shared.buttons.choosePlan')}</h4>{' '}
                  <img src={Next} alt="next" />
                </>
              )}
            </button>
          </div>
        </div>
        <div className="w-full md:w-[25%] flex h-[400px] justify-start">
          <div className="w-full  flex flex-col space-y-6 justify-center bg-white px-8 py-2 rounded-[45px] border-8 border-white">
            <div className="flex justify-center md:justify-start items-center space-x-3">
              <img src={Yearly} alt="yearly" />
              <div className="flex flex-col ">
                <h3 className="text-[#262B33] text-[19px] font-medium">
                  {t('pricing.step1.cards.2.title')}
                </h3>
                <p className="text-[#262B33] text-[26px] font-bold pl-5">
                  <span className="text-[#B7B8BC] absolute text-[16px] mt-[-1px] ml-[-11px] ">
                    $
                  </span>
                  {t('pricing.step1.cards.2.price')}
                </p>
              </div>
            </div>
            <div className="border-b-[1px] border-solid border-[#B4B6C2]" />
            <div className="flex w-full items-center justify-start space-x-6">
              <img src={GreenWhite} alt="tick white" />
              <p className="text-[#67707E] text-[14px]">
                {t('pricing.step1.cards.2.features.0.0')}{' '}
                <span className="text-[#262B33] font-bold">
                  {t('pricing.step1.cards.2.features.0.1')}{' '}
                </span>{' '}
                {t('pricing.step1.cards.2.features.0.2')}
              </p>
            </div>
            {/* <div className='flex w-full items-center justify-start space-x-6'>
                            <img src={GreenWhite} alt="tick white" />
                            <p className='text-[#67707E] text-[14px]'>Unlimited <span className='text-[#262B33] font-bold'>Cover</span> Letters</p>
                        </div> */}
            <div className="flex w-full items-center justify-start space-x-6">
              <img src={GreenWhite} alt="tick white" />
              <p className="text-[#67707E] text-[14px]">
                {t('pricing.step1.cards.2.features.1.0')}{' '}
                <span className="text-[#262B33] font-bold">
                  {' '}
                  {t('pricing.step1.cards.2.features.1.1')}
                </span>
              </p>
            </div>
            <div className="flex w-full items-center justify-start space-x-6">
              <img src={GreenWhite} alt="tick white" />
              <p className="text-[#67707E] text-[14px]">
                <span className="text-[#262B33] font-bold">
                  {' '}
                  {t('pricing.step1.cards.2.features.2.0')}
                </span>{' '}
                {t('pricing.step1.cards.2.features.2.1')}
              </p>
            </div>

            {/* <div className='flex w-full items-center justify-start space-x-6'>
                            <img src={GreenWhite} alt="tick white" />
                            <p className='text-[#67707E] text-[14px]'>Free resume critique from an <span className='text-[#262B33] font-bold'>HR expert</span></p>
                        </div> */}
            <button
              className="bg-[#3D41F1] flex justify-center mt-[50px!important]  items-center space-x-6 text-white text-[21px] font-medium rounded-lg py-6 px-8"
              onClick={() =>
                onPriceClick(_config.subscriptions.yearly, 29.99, 'Yearly')
              }
            >
              {loading === 'Yearly' ? (
                <ProcessingButton />
              ) : (
                <>
                  <h4>{t('shared.buttons.choosePlan')}</h4>{' '}
                  <img src={Next} alt="next" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="container flex-wrap mx-auto flex space-x-8 justify-center items-center py-12">
        <p className="text-[18px] font-semibold text-[#262B33]">
          {' '}
          {t('pricing.step1.weAccept')}:
        </p>
        <img src={Visa} alt="visa" />
        <img src={Master} alt="master" />
        <img src={Express} alt="express" />
        <img src={Paypal} alt="paypal" />
      </div>
      <div className="container mx-auto px-[20px] md:px-[200px] text-center flex space-x-8 justify-center items-center py-12">
        <p className="text-[17px] font-medium text-[#6A7683]">
          {t('pricing.step1.footerDescription')}
        </p>
      </div>
    </>
  );
}
