import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '../router/LoadingScreen';

export default function CancelSub({ subid, userid }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const config = {
      method: 'POST',
      url: '/api/get-user-info',
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': 'https://www.simplecv.me',
        Accept: 'application/json',
      },
      data: {
        subid,
        userid,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res);
        setType(res.data.interval);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const cancelSub = () => {
    const config = {
      method: 'POST',
      url: '/api/cancel-user-sub',
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': 'https://www.simplecv.me',
        Accept: 'application/json',
      },
      data: {
        subid,
        userid,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res);
        window.location.href = '/app/dashboard/?success=true';
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading) {
    return (
      <div className="w-full h-[500px] bg-[#F2F5FA] py-40">
        <LoadingScreen />
      </div>
    );
  }

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="w-full h-full flex justify-center items-center">
          <div className=" w-[400px] px-6 flex flex-col justify-around py-4 bg-white h-[160px] rounded-md shadow-md">
            <h1 className="text-[16px] text-gray-800 font-semibold ">
              {t('subscription.modal.title')}
            </h1>
            <div className="space-x-2">
              <button
                className="px-3 py-1 text-[14px] bg-[#3D41F1] text-white rounded-md shadow-md"
                onClick={() => cancelSub()}
              >
                {t('shared.buttons.accept')}
              </button>
              <button
                className="px-3 py-1 text-[14px] bg-white text-[#3D41F1] rounded-md shadow-md"
                onClick={() => handleClose()}
              >
                {t('shared.buttons.cancel')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-full bg-[#F2F5FA] py-40">
        <div className="container m-auto flex flex-wrap justify-center items-center mt-12 space-y-6 md:space-y-0">
          <div className="w-full md:w-[30%] flex h-[250px] justify-center">
            <div className="w-full  flex flex-col space-y-8 justify-center bg-[#1A1D29] px-8 py-2  rounded-[45px] border-8 border-white">
              <div className="flex justify-around items-center space-x-3">
                <h1 className="text-[#FEFEFE] text-[28px] font-semibold">
                  {t('subscription.title')}
                </h1>
                <div className="flex flex-col ">
                  <h3 className="text-[#FEFEFE] text-[22px] font-medium uppercase">
                    {type}
                  </h3>
                  <p className="text-[#FEFEFE] text-[31px] font-bold pl-4">
                    <span className="text-[#B7B8BC] absolute text-[18px] mt-[-1px] ml-[-7px] ">
                      $
                    </span>
                    {type === 'month'
                      ? '44.95'
                      : type === 'year'
                      ? '74.95'
                      : '2.95'}
                  </p>
                </div>
              </div>
              <button
                onClick={() => handleOpen()}
                className="bg-[#3D41F1] flex justify-center  items-center space-x-6 text-white text-[21px] font-medium rounded-lg py-6 px-8"
              >
                <h4>{t('shared.buttons.cancelSub')}</h4>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
